import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="st-page-not-found st-flex-center text-center">
      <div className="">
        <h3>✅</h3>
        <h4>Gracias por contactarnos</h4>
        <p>Nos vamos a contactar a la brevedad!</p>
        <Link to='/' className="st-btn st-style1 st-color1">Volver al sitio</Link>
      </div>
    </div>
  )
}

export default PageNotFound;
