import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Social = () => {
  return (
    <ul className="st-social-btn st-style1 st-mp0">
      <li>
        <Link to='https://www.instagram.com/sye.dental/' target='_blank'>
          <Icon icon="fa6-brands:square-instagram" />
        </Link>
      </li>
      <li>
        <Link to='wa.me/message/4777RBB3QQ4OP1' target='_blank'>
          <Icon icon="fa6-brands:whatsapp" />
        </Link>
      </li>
      <li>
        <Link to='' target='_blank'>
          <Icon icon="fa6-brands:facebook" />
        </Link>
      </li>
    </ul>
  )
}

export default Social;
