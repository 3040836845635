import React from 'react';
import Hero6 from '../Components/Hero/Hero6';
import LocationInMap from '../Components/Map/LocationInMap';
import Contact from '../Components/Contact/Contact';
import Department from '../Components/Department/Department';
import Appointment from '../Components/Appointment/Appointment';
import About2 from '../Components/About/About2';
import Iconbox from '../Components/Iconbox/Iconbox';
import PostWrapper from '../Components/Post/PostWrapper';
import SpecialistsSlider from '../Components/Slider/SpecialistsSlider';
import TestimonialSlider from '../Components/Slider/TestimonialSlider';
import BrandSlider from '../Components/Slider/BrandSlider';
import Newsletter from '../Components/Newsletter/Newsletter';
import Accordion from '../Components/Accordion/Accordion';
import PriceSlider from '../Components/Slider/PriceSlider';
import Funfact from '../Components/Funfact/Funfact';
import BeforeAfter from '../Components/BeforeAfter/BeforeAfter';
import MasonryGallery from '../Components/Gallery/Gallery';
const heroData = {
  bgImg: 'images/hero-bg9.jpg',
  bgShape: 'shape/hero-shape.png',
  sliderImages: [
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
    {
      img: 'images/hero-img.png',
    },
    {
      img: 'images/hero-img1.png',
    },
    {
      img: 'images/hero-img2.png',
    },
  ],
  title: ['sonrisas', 'dientes', 'selfies', 'looks', 'días', 'mordidas']
};

const iconboxData = [
  {
    bg: 'purple',
    icon: 'icons/icon1.svg',
    title: 'Profesionales Calificados',
    subTitle:
      'Contamos con un equipo de profesionales altamente calificados y experimentados en el área de la salud y estética dental para garantizarte la mejor experiencia.',
  },
  {
    bg: 'green',
    icon: 'icons/icon2.svg',
    title: 'Atención vía Whatsapp',
    subTitle:
      'Brindamos atención personalizada a través de Whatsapp para que puedas resolver tus dudas y agendar tus citas de forma rápida y cómoda.',
  },
  {
    bg: 'red',
    icon: 'icons/icon3.svg',
    title: 'Equipos de última tecnología',
    subTitle:
      'Contamos con los equipos de última tecnología en el área de la salud y estética dental para ofrecerte los mejores tratamientos disponibles.',
  },
];
const aboutData = {
  title: 'Quienes Somos.',
  subTitle: 'Somos un grupo de profesionales odontólogos que buscamos la precisión en cada diagnóstico y la excelencia en cada tratamiento. La esencia de nuestra labor es la pasión por lo que hacemos, y la búsqueda incansable e incesable de mejorar día a día nuestro servicio, capacitándonos e invirtiendo en equipamiento de última generación.<br/> <br/> Brindar servicio odontológico integral y especializado de calidad con profesionales de alto nivel comprometidos con el cuidado de la salud bucal para lograr la satisfacción y fidelización de nuestros pacientes mediante un sistema docente asistencial. <br/> <br/> ',
  img: 'images/hero-bg3.jpg',
  avater: {
    img: 'images/avatar1.png',
    name: ' Osvaldo Fadel',
    designation: 'Fundador y CEO',
  },
};
const specialistData = [
  {
    img: 'images/member1.jpg',
    name: 'Dr. Philip Bailey',
    designation: 'Urology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member2.jpg',
    name: 'Dr. Vera Hasson',
    designation: 'Cardiology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member3.jpg',
    name: 'Dr. Matthew Hill',
    designation: 'Neurosurgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member4.jpg',
    name: 'Dr. Jeanette Hoff',
    designation: 'Surgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member1.jpg',
    name: 'Dr. Philip Bailey',
    designation: 'Urology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member2.jpg',
    name: 'Dr. Vera Hasson',
    designation: 'Cardiology',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member3.jpg',
    name: 'Dr. Matthew Hill',
    designation: 'Neurosurgery',
    authorLink: '/doctor-profile',
  },
  {
    img: 'images/member4.jpg',
    name: 'Dr. Jeanette Hoff',
    designation: 'Surgery',
    authorLink: '/doctor-profile',
  },
];

const beforeAfterData = {
  bgImg: '/images/before-after-bg.jpg',
  beforeImg: '/images/after.jpg',
  afterImg: 'images/before.jpg',
};

const testimonialData = [
  {
    "img": "images/avatar3.png",
    "name": "Agustina Fernández",
    "designation": "Docente",
    "subTitle": "' Solía tenerle mucho miedo al dentista, ¡pero todos en Syedental son tan amables y tranquilos que ahora de verdad espero con ansias mis citas! Mi sonrisa está más brillante y saludable que nunca, y se lo debo a todo su increíble equipo.'"
  },
  {
    "img": "images/avatar2.png",
    "name": "Juan Martínez",
    "designation": "Ingeniero",
    "subTitle": "'Desde el momento en que entré, me sentí bienvenido y cuidado. El personal es increíblemente profesional y capacitado, y se tomaron el tiempo para responder todas mis preguntas e inquietudes. Recomiendo altamente Syedental a cualquiera que busque una experiencia dental de primera categoría.'"
  },
  {
    "img": "images/avatar4.png",
    "name": "Matías González",
    "designation": "Vendedor",
    "subTitle": "'Había descuidado mis dientes durante años y me sentía muy cohibido por mi sonrisa. El Dr. Osvaldo y su equipo transformaron por completo mi sonrisa con carillas. Ahora no puedo dejar de sonreír, y he recuperado mucha confianza. ¡Gracias!.'"
  }
];

const priceData = [
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
  {
    title: 'Blood Test',
    price: '39',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '0',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Hemoglobin Test',
    price: '89',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '0',
      },
    ],
  },
  {
    title: 'Homocysteine Test',
    price: '150',
    featureList: [
      {
        title: 'First Description',
        status: '1',
      },
      {
        title: 'Second Description',
        status: '1',
      },
      {
        title: 'Third Description',
        status: '1',
      },
      {
        title: 'Fourth Description',
        status: '1',
      },
      {
        title: 'Fifth Description',
        status: '1',
      },
    ],
  },
];

const faqData = {
  title: 'Preguntas Frecuentes',
  img: 'images/faq-img.png',
  bgImg: 'shape/faq-bg.svg',
  faqItems: [
    {
      "title": "¿Qué servicios dentales ofrecen?",
      "content": "Ofrecemos una amplia gama de servicios dentales, incluyendo limpiezas dentales, empastes, extracciones, blanqueamiento dental, coronas, puentes, implantes dentales, tratamientos de conducto, endodoncia, ortodoncia, y mucho más."
    },
    {
      "title": "¿Qué tecnología utilizan en su clínica?",
      "content": "Utilizamos tecnología de última generación para brindar la mejor atención posible a nuestros pacientes. Esto incluye radiografías digitales, escáneres intraorales, cámaras intraorales, y software de diseño dental avanzado."
    },
    {
      "title": "¿Qué métodos de pago aceptan?",
      "content": "Aceptamos efectivo, tarjetas de crédito y débito, y algunos planes de seguro dental. Consulta con nuestro equipo para obtener más información sobre las opciones de pago disponibles."
    },
    {
      "title": "¿Ofrecen consultas virtuales?",
      "content": "Sí, ofrecemos consultas virtuales para citas de seguimiento, revisiones de rutina y consultas iniciales. Esto le permite ahorrar tiempo y evitar desplazamientos."
    },
    {
      "title": "¿Qué experiencia tienen sus odontólogos?",
      "content": "Nuestros odontólogos cuentan con amplia experiencia y están altamente capacitados en las últimas técnicas y procedimientos dentales. Muchos de ellos también tienen certificaciones y membresías en asociaciones profesionales."
    },
    {
      "title": "¿Puedo ver fotos del antes y después de sus tratamientos?",
      "content": "Sí, tenemos una galería de fotos del antes y después de algunos de nuestros tratamientos en nuestro sitio web o redes sociales. También puede solicitar ver fotos durante su consulta."
    }
  ]
};

const newsletterData = {
  bgImg: 'images/news-letter-bg.png',
  contact: '(+01) - 234 567 890',
};

const postData = [
  {
    img: 'images/blog1.jpg',
    title: 'Working in emergency medicine',
    date: 'Aug 23, 2020',
    author: 'Albert Brian',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog2.jpg',
    title: 'Individual treatment & assistance',
    date: 'Aug 22, 2020',
    author: 'William Juarez',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
  {
    img: 'images/blog3.jpg',
    title: 'Child’s first hospital visit',
    date: 'Aug 21, 2020',
    author: 'Jamse Lewis',
    authorLink: '',
    subTitle:
      "Lorem Ipsum is simply dummy text of the print ing and typesetting industry. lorem Ipsum the industry's standard dummy text.",
    postLink: '/post/post_details',
  },
];

const brandData = [
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
  {
    bg: 'orange',
    img: 'images/client1.png',
  },
  {
    bg: 'blue',
    img: 'images/client2.png',
  },
  {
    bg: 'red',
    img: 'images/client3.png',
  },
  {
    bg: 'green',
    img: 'images/client4.png',
  },
  {
    bg: 'dip-blue',
    img: 'images/client5.png',
  },
];

const mapLocationURL =
  'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d52580.40674072899!2d-58.498714299999996!3d-34.5465813!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb702582bce21%3A0xc10093393eebf04d!2sS%26EDental!5e0!3m2!1sen!2sar!4v1708119027897!5m2!1sen!2sar';

const Home = () => {
  return (
    <>
      <Hero6 data={heroData} />
      <About2 data={aboutData} />
      <Iconbox data={iconboxData} />
      <hr />
      <MasonryGallery />
      <BeforeAfter data={beforeAfterData} />
      <TestimonialSlider data={testimonialData} />
      <Accordion data={faqData} />
      <Contact />
    </>
  );
};

export default Home;
