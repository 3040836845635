import React, { useState } from 'react'
import SectionHeading from '../SectionHeading/SectionHeading'

const Contact = () => {
/* 
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    msg: ''
  });

  // Handler for input field changes
  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);
    formData.append("access_key", "fcc74231-656a-425b-a54f-aff38354fadb");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        msg: ''
      });
      setLoading(false)
    }
  };
 */


  return (
    <section className="st-shape-wrap" id="contact">
      <div className="st-shape1">
        <img src="shape/contact-shape1.svg" alt="shape1" />
      </div>
      <div className="st-shape2">
        <img src="shape/contact-shape2.svg" alt="shape2" />
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
      {/* <SectionHeading title="Contacto"
        subTitle="Dejanos tus datos en el siguiente formulario para que podamos contactarte de inmediato con alguno <br></b> de nuestros profesionales disponibles para poder asesorarte a sacar turno" /> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div id="st-alert" />
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf63bEaeP-PjGJFgf2EzRFwLwe6NkNTbyeJisz0xwexfBKehQ/viewform?embedded=true" width="640" height="1100" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
          </div>
          <div className="col-lg-6" style={{boxShadow: 'rgba(0, 0, 0, 0.4) 0px 30px 90px;'}}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d105160.8138605486!2d-58.49871400000001!3d-34.546581!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb702582bce21%3A0xc10093393eebf04d!2sS%26EDental!5e0!3m2!1sen!2sar!4v1708533326228!5m2!1sen!2sar" width="600" height="1050"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{borderRadius: '50px'}}></iframe>
          </div>
          {/* .col */}
        </div>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </section>
  )
}

export default Contact
